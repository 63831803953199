/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { Card, TextField, Grid } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useState } from "react";
import dayjs from "dayjs";

import MDEditor from "components/MDEditor";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MDDropzone from "components/MDDropzone";
import AttachedDocument from "components/AttachedDocument";
import useModal from "hooks/useModal";
import ConfirmModal from "components/modal/ConfirmModal";

const SupportingDocumentsEvaluations = ({ isMobile, data, setData }) => {
  const { openModal } = useModal();
  const [uploadedFiles, setUpoadedFiles] = useState(data.uploadedFiles);
  const [notification, setNotification] = useState(false);
  const [email, setEmail] = useState(false);
  const [evaluationComment, setEvaluationComment] = useState();

  const setNotificationFn = (event) => {
    data.evaluationNotification = event.target.checked;
    setData(data);
    setNotification(event.target.checked);
  };

  const setEmailFn = (event) => {
    data.evaluationEmail = event.target.checked;
    setData(data);
    setEmail(event.target.checked);
  };

  const setEvaluationCommentFn = (val) => {
    data.evaluationComment = val;
    setData(data);
    setEvaluationComment(val);
  };

  const [nextEvaluationDate, setNextEvaluationDate] = useState(
    dayjs.unix(data.nextEvaluationDate).format("MM/DD/YYYY")
  );
  const [evaluationDate, setEvaluationDate] = useState(
    dayjs.unix(data.evaluationDate).format("MM/DD/YYYY")
  );
  const isNew = data.id === -1;

  const setNextEvaluationDateFn = (value) => {
    const val = dayjs(value).unix();
    data.nextEvaluationDate = val;
    setData(data);
    setNextEvaluationDate(value);
  };

  const setEvaluationDateFn = (value) => {
    const val = dayjs(value).unix();
    data.evaluationDate = val;
    setData(data);
    setEvaluationDate(value);
  };

  const cancelConfirm = (index) => {
    uploadedFiles[index].markedForDelete = true;
    data.uploadedFiles = uploadedFiles;
    setData(data);
    setUpoadedFiles([...uploadedFiles]);
    return "success";
  };

  function deleteFile(index) {
    openModal(
      <ConfirmModal
        buttonLabels={["no", "yes"]}
        icon="warn"
        text="Do you really want to delete this file?"
      />,
      () => cancelConfirm(index)
    );
  }

  function undoDelete(index) {
    uploadedFiles[index].markedForDelete = false;
    data.uploadedFiles = uploadedFiles;
    setData(data);
    setUpoadedFiles([...uploadedFiles]);
  }

  const onFilesChanged = (files) => {
    const newFiles = uploadedFiles.concat(files);
    data.uploadedFiles = newFiles;
    setData(data);
    setUpoadedFiles(newFiles);
  };

  return (
    <>
      <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Supporting Documents (Optional)
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="body2" fontSize="xs">
            Attach any supporting documents for future reference.
          </MDTypography>
          <Grid container mx={2} mt={1.5} mb={2}>
            {uploadedFiles !== null &&
              uploadedFiles.map((file, index) => (
                <Grid item xs={12} md={6} xl={4} xxxl={3} key={`attachedcoc-${index}`}>
                  <AttachedDocument
                    key={`files-${index}`}
                    file={file}
                    deleteMe={() => deleteFile(index)}
                    undoDelete={() => undoDelete(index)}
                  />
                </Grid>
              ))}
          </Grid>
          <MDDropzone
            options={{
              addRemoveLinks: true,
              uploadMultiple: true,
              dictDefaultMessage: "Click or Drag and drop files here",
            }}
            onFilesChangedCallback={onFilesChanged}
          />
        </MDBox>
      </Card>
      <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              {isNew ? "Next Evaluation" : "Evaluation"}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          {!isNew && (
            <MDBox mr={4} mb={2} pb={2} sx={{ borderBottom: `1px solid ${colors.grey[400]}` }}>
              <MDTypography variant="body2" fontSize="xs">
                Evaluation Date
              </MDTypography>
              <MDBox mb={2}>
                <DesktopDatePicker
                  minDate={data.startDate}
                  value={evaluationDate}
                  onChange={(newValue) => {
                    setEvaluationDateFn(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: 200, backgroundColor: "white", borderRadius: "8px" }}
                    />
                  )}
                />
              </MDBox>
              <MDTypography variant="body2" fontSize="xs">
                Revisit the goals and barriers, and add any evaluation comments that you might have.
              </MDTypography>

              <MDEditor
                placeholder={isMobile ? "Evaluation Comments.." : "Enter Evaluation Comments.."}
                hideToolbar={isMobile}
                minHeight={100}
                value={evaluationComment}
                onChange={(newValue) => setEvaluationCommentFn(newValue)}
              />
            </MDBox>
          )}

          <MDBox>
            <MDTypography variant="body2" fontSize="xs">
              {isNew
                ? "Set a tentative date for the next evaluation."
                : "If the QAPI is still not resolved, set another future evaluation date."}
            </MDTypography>
            <MDBox display="flex" pt={2} flexWrap="wrap">
              <MDBox mr={4} mb={2}>
                <MDTypography variant="body2" fontSize="xs">
                  Next Evaluation Date
                </MDTypography>
                <DesktopDatePicker
                  minDate={dayjs().format("MM/DD/YYYY")}
                  value={nextEvaluationDate}
                  onChange={(newValue) => {
                    setNextEvaluationDateFn(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: 200, backgroundColor: "white", borderRadius: "8px" }}
                    />
                  )}
                />
              </MDBox>
              <MDBox sx={{ borderLeft: `1px solid ${colors.grey[400]}` }} pl={4}>
                <MDTypography variant="body2" fontSize="xs">
                  Remind Me
                </MDTypography>
                <MDBox display="flex" justifyContent="space-between" sx={{ width: 265 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="notification"
                        checked={notification}
                        onChange={(event) => setNotificationFn(event)}
                      />
                    }
                    label="Notification"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="email"
                        checked={email}
                        onChange={(event) => setEmailFn(event)}
                      />
                    }
                    label="Email"
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default SupportingDocumentsEvaluations;
