import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import Icon from "@mui/material/Icon";
import useBackendError from "hooks/useBackendError";

import Inspections from "components/StarRating/Inspections";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import InspectionModal from "components/modal/InspectionModal";
import useModal from "hooks/useModal";
import Scrollbars from "react-custom-scrollbars";
import FiveStarOverviewMain from "./FiveStarOverviewMain";
import FiveStarPast from "./FiveStarPast";

const FivestarOverview = () => {
  const [isLoadingInspections, setLoadingInsepctions] = useState(true);
  const [isLoadingPastScores, setLoadingPastScores] = useState(true);
  const inspections = useRef([{}, {}, {}]);
  const pastScores = useRef([]);
  const axios = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const { timeline } = auth.profile;
  const { setError } = useBackendError();

  const loadInspectionData = async () => {
    setLoadingInsepctions(true);
    inspections.current = [];

    try {
      const response = await axios.get(
        `/inspections/list?type=${timeline.type}&startDate=${timeline.startDate}&endDate=${timeline.endDate}&quarter=${timeline.quarter}&year=${timeline.year}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      inspections.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoadingInsepctions(false);
    }
  };

  const loadPastScoreData = async () => {
    setLoadingPastScores(true);
    pastScores.current = [];

    try {
      const response = await axios.get(`/fivestar/past`, {
        headers: { "Content-Type": "application/json" },
      });
      pastScores.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoadingPastScores(false);
    }
  };

  const saveData = async (inspectionsUpdate, inspectionIndex, number) => {
    console.log("saving..");
    setLoadingInsepctions(true);

    /*
    try {
      const response = await axios.get(
        `/inspections/list?type=${timeline.type}&startDate=${timeline.startDate}&endDate=${timeline.endDate}&quarter=${timeline.quarter}&year=${timeline.year}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      inspections.current = response.data;
      setItem("inspections", response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoadingInsepctions(false);
    }
    */

    setLoadingInsepctions(false);
    console.log(inspectionsUpdate);
  };

  const updateTimeline = (val) => {
    const newAuth = auth;
    newAuth.profile.timeline = val;
    setAuth(newAuth);
    loadInspectionData();
  };

  const { openModal } = useModal();

  const saveInspections = (inspectionsUpdate, inspectionIndex, number) => {
    saveData(inspectionsUpdate, inspectionIndex, number);
  };

  const newAnnualInspection = () => {
    inspections.current.push({ revisits: [], fTags: [] });
    openModal(
      <InspectionModal
        isNew
        number={0}
        inspectionIndex={inspections.current.length - 1}
        inspections={inspections.current}
        saveInspections={saveInspections}
        isComplaint={false}
      />,
      () => {}
    );
  };

  const newComplaintInspection = () => {
    inspections.current.push({ revisits: [], fTags: [] });
    openModal(
      <InspectionModal
        isNew
        number={0}
        inspectionIndex={inspections.current.length - 1}
        inspections={inspections.current}
        saveInspections={saveInspections}
        isComplaint
      />,
      () => {}
    );
  };

  const scrollbar = useRef();

  useEffect(() => {
    loadInspectionData();
    loadPastScoreData();
  }, []);

  return (
    <>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <FiveStarOverviewMain data={inspections.current} isLoading={isLoadingInspections} />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <Card id="past-surveys">
            <MDBox
              display="flex"
              justifyContent="space-between"
              px={2}
              pt={2}
              alignItems="flex-end"
            >
              <MDTypography variant="h6" fontWeight="medium" width={280}>
                Health Inspections
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" px={2} alignItems="flex-end">
              <MDBox>
                <MDTypography
                  variant="subtitle1"
                  fontWeight="medium"
                  style={{ fontSize: 11, marginTop: 10, marginLeft: 6 }}
                  color="text"
                >
                  ANNUAL
                </MDTypography>
              </MDBox>

              <MDButton variant="gradient" color="info" onClick={newAnnualInspection}>
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> &nbsp; NEW ANNUAL INSPECTION
              </MDButton>
            </MDBox>

            <Scrollbars
              ref={scrollbar}
              style={{
                height:
                  !isLoadingInspections &&
                  inspections.current != null &&
                  inspections.current.length > 1
                    ? 222
                    : 170,
                backgroundColor: colors.grey[400],
                borderRadius: 8,
                marginLeft: 20,
                marginTop: 10,
                marginBottom: 10,
                width: "calc(100% - 40px)",
                border: `1px solid ${colors.grey[400]}`,
              }}
              renderTrackHorizontal={({ style, ...props }) => <div />}
              renderTrackVertical={({ style, ...props }) => (
                <div className="scrollbar-regular-vertical-track" {...props} />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  style={{
                    ...style,
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    borderRadius: 8,
                    width: 6,
                    marginRight: -3,
                    cursor: "pointer",
                  }}
                  {...props}
                />
              )}
            >
              <Inspections
                data={inspections.current.filter((insp) => insp.complaint !== true)}
                isLoading={isLoadingInspections}
                saveInspections={saveInspections}
              />
            </Scrollbars>

            <MDBox display="flex" justifyContent="space-between" px={2} pt={2}>
              <MDBox>
                <MDTypography
                  variant="subtitle1"
                  fontWeight="medium"
                  style={{ fontSize: 11, marginTop: 24, marginLeft: 6 }}
                  color="text"
                  alignItems="flex-end"
                >
                  COMPLAINT
                </MDTypography>
              </MDBox>

              <MDButton variant="gradient" color="info" onClick={newComplaintInspection}>
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> &nbsp; NEW COMPLAINT INSPECTION
              </MDButton>
            </MDBox>

            <Scrollbars
              ref={scrollbar}
              style={{
                height:
                  !isLoadingInspections &&
                  inspections.current != null &&
                  inspections.current.length > 1
                    ? 222
                    : 170,
                backgroundColor: colors.grey[400],
                borderRadius: 8,
                marginLeft: 20,
                marginTop: 10,
                marginBottom: 10,
                width: "calc(100% - 40px)",
                border: `1px solid ${colors.grey[400]}`,
              }}
              renderTrackHorizontal={({ style, ...props }) => <div />}
              renderTrackVertical={({ style, ...props }) => (
                <div className="scrollbar-regular-vertical-track" {...props} />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  style={{
                    ...style,
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    borderRadius: 8,
                    width: 6,
                    marginRight: -3,
                    cursor: "pointer",
                  }}
                  {...props}
                />
              )}
            >
              <Inspections
                data={inspections.current.filter((insp) => insp.complaint === true)}
                isLoading={isLoadingInspections}
                saveInspections={saveInspections}
                isComplaint
              />
            </Scrollbars>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <FiveStarPast data={pastScores.current} isLoading={isLoadingPastScores} />
        </Grid>
      </Grid>
    </>
  );
};
export default FivestarOverview;
