import Card from "@mui/material/Card";
import DataTable from "components/DataTable";
import TimestampCell from "components/DataTable/components/TimestampCell";
import DefaultCell from "components/DataTable/components/DefaultCell";
import PersonCell from "components/DataTable/components/PersonCell";
import SatisfactionScoreCell from "components/DataTable/components/SatisfactionScoreCell";
import PastSatisfactionActionCell from "components/DataTable/components/ActionCells/PastSatisfactionActionCell";

const dataTableData = (rows, isLoading) => ({
  columns: [
    {
      Header: "Resident",
      accessor: "resident",
      isSorted: true,
      Cell: ({ value: [image, name, id] }) => (
        <PersonCell image={image} name={name} color="light" id={id} isLoading={isLoading} />
      ),
    },
    {
      Header: "Surveyed By",
      accessor: "surveyedBy",
      isSorted: true,
      Cell: ({ value: [image, name, id] }) => (
        <PersonCell image={image} name={name} color="light" id={id} isLoading={isLoading} />
      ),
    },
    {
      Header: "Date",
      accessor: "date",
      isSorted: false,
      Cell: ({ value }) => <TimestampCell timestamp={value} hideTime isLoading={isLoading} />,
    },
    {
      Header: "Template",
      accessor: "template",
      isSorted: false,
      Cell: ({ value }) => <DefaultCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Score",
      accessor: "score",
      isSorted: false,
      Cell: ({ value }) => <SatisfactionScoreCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Actions",
      accessor: "action",
      isSorted: false,
      Cell: ({ value }) => <PastSatisfactionActionCell id={value} isLoading={isLoading} />,
    },
  ],
  rows,
});

const PastSurveys = ({ setTimeline, data, isLoading }) => {
  let rows = [];

  if (!isLoading) {
    rows = data?.map((row) => ({
      resident: [row.resident.url, row.resident.fullName, row.resident.id],
      surveyedBy: [row.user.url, row.user.fullName, row.user.id],
      date: row.completedDate,
      template: row.template.name,
      score: row.score,
      action: row.id,
    }));
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      // eslint-disable-next-line no-new-object
      rows.push({
        resident: ["", "", 0],
        surveyedBy: ["", "", 0],
        date: 0,
        template: 0,
        score: 0,
        action: 0,
      });
    }
  }

  if (rows == null) {
    return null;
  }

  return (
    <Card id="past-rating">
      <DataTable
        table={dataTableData(rows, isLoading)}
        title="Past Surveys"
        isSorted
        canSearch
        setTimeline={setTimeline}
        entriesPerPage={{ defaultValue: 10, hide: true }}
      />
    </Card>
  );
};

export default PastSurveys;
