/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import { Card } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { useState } from "react";
import BarriersAndGoalsModal from "components/modal/BarriersAndGoalsModal";
import useModal from "hooks/useModal";
import BarriersAndGoalsExisting from "./BarriersAndGoalsExisting";

const BarriersAndGoals = ({ isMobile, data, setData }) => {
  const [barriers, setBarriers] = useState(data.barriers ?? []);
  const [goals, setGoals] = useState(data.goals ?? []);

  const { openModal } = useModal();

  const setBarriersFn = (val, index) => {
    data.barriers[index] = val;
    setData(data);
    setBarriers([...data.barriers]);
  };

  const setGoalsFn = (val, index) => {
    data.goals[index] = val;
    setData(data);
    setGoals([...data.goals]);
  };

  const deleteNow = (index, isGoal) => {
    if (isGoal) {
      data.goals.splice(index, 1);
      setData(data);
      setGoals([...data.goals]);
    } else {
      data.barriers.splice(index, 1);
      setData(data);
      setBarriers([...data.barriers]);
    }
  };

  const callback = ({ action, newText, isGoal, index }) => {
    if (action === "cancel") {
      return;
    }
    if (action === "add") {
      if (isGoal) {
        data.goals.push({
          text: newText,
          status: "ON_GOING",
          date: 1679442151,
          lastDateOperation: "Added",
        });
      } else {
        data.barriers.push({
          text: newText,
          status: "ON_GOING",
          date: 1679442151,
          lastDateOperation: "Added",
        });
      }
      setData(data);
      if (isGoal) {
        setGoals([...data.goals]);
      } else {
        setBarriers([...data.barriers]);
      }
    } else {
      if (isGoal) {
        data.goals[index].text = newText;
        data.goals[index].date = 1679442151;
        data.goals[index].lastDateOperation = "Updated";
      } else {
        data.barriers[index].text = newText;
        data.barriers[index].date = 1679442151;
        data.barriers[index].lastDateOperation = "Updated";
      }
      setData(data);
      if (isGoal) {
        setGoals([...data.goals]);
      } else {
        setBarriers([...data.barriers]);
      }
    }
  };

  const openDetails = (isGoal, index, isNew) => {
    openModal(
      <BarriersAndGoalsModal isGoal={isGoal} index={index} data={data} isNew={isNew} />,
      callback
    );
  };

  return (
    <>
      <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Barriers
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="body2" fontSize="xs">
            List out the barriers so that you can set some goals in the next section to resolve
            them. You can evaluate them on the next evaluation.
          </MDTypography>
          {barriers != null &&
            barriers.map((barrier, index) => (
              <BarriersAndGoalsExisting
                key={`barrier-${index}`}
                isMobile={isMobile}
                data={barrier}
                setData={setBarriersFn}
                isGoal={false}
                index={index}
                onClick={() => openDetails(false, index, false)}
                deleteMe={deleteNow}
                editMe={() => openDetails(false, index, false)}
              />
            ))}
          <MDBox
            style={{ borderTop: `1px solid ${colors.grey[400]}`, marginTop: 10, paddingTop: 5 }}
          >
            <MDButton
              variant="gradient"
              color="info"
              style={{ marginTop: 5 }}
              onClick={() => openDetails(false, -1, true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;add new
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Goals
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="body2" fontSize="xs">
            Set some goals to arrive at a resolution. You can evaluate the set goals on the next
            evaluation.
          </MDTypography>
          {goals != null &&
            goals.map((goal, index) => (
              <BarriersAndGoalsExisting
                key={`goals-${index}`}
                isMobile={isMobile}
                data={goal}
                setData={setGoalsFn}
                isGoal
                index={index}
                onClick={() => openDetails(true, index, false)}
                deleteMe={deleteNow}
                editMe={() => openDetails(true, index, false)}
              />
            ))}
          <MDBox
            style={{ borderTop: `1px solid ${colors.grey[400]}`, marginTop: 10, paddingTop: 5 }}
          >
            <MDButton
              variant="gradient"
              color="info"
              style={{ marginTop: 5 }}
              onClick={() => openDetails(true, -1, true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;add new
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default BarriersAndGoals;
