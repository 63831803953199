/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { Card } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import useModal from "hooks/useModal";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import InputModal from "components/modal/InputModal";
import ResidentModal from "components/modal/ResidentModal";

const useStyles = makeStyles({
  checkedIcon: {
    backgroundColor: colors.grey[500],
    borderRadius: 4,
    marginLeft: 1,
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  button_delete: {
    "&:hover": {
      color: colors.error.main,
    },
  },
});

const PeopleInvolved = ({ isLoading, data, setData }) => {
  const { openModal } = useModal();

  const classes = useStyles();

  const [teamMembers, setTeamMembers] = useState(data.teamMembers);
  const [residents, setResidents] = useState(data.residents ?? []);

  const memberSelect = (event, index) => {
    data.teamMembers[index].selected = event.target.checked;
    setData(data);
    setTeamMembers([...data.teamMembers]);
  };

  const deleteResidentCallback = async (arg) => {
    data.residents.splice(arg, 1);
    setData(data);
    setResidents([...data.residents]);
    return "success";
  };

  const deleteMemberCallback = async (arg) => {
    data.teamMembers.splice(arg, 1);
    setData(data);
    setTeamMembers([...data.teamMembers]);
    return "success";
  };

  const deleteResident = (name, index) => {
    openModal(
      <ConfirmDeleteModal
        title="Remove resident?"
        text={`Do you really want to remove ${name}?`}
        successText={`${name} has been successfully remove.`}
        callback={deleteResidentCallback}
        arg={index}
      />,
      () => {}
    );
  };

  const deleteMember = (name, index) => {
    openModal(
      <ConfirmDeleteModal
        title="Delete Team Member?"
        text={`Do you really want to delete ${name}?`}
        successText={`${name} has been successfully deleted.`}
        callback={deleteMemberCallback}
        arg={index}
      />,
      () => {}
    );
  };

  const addNewResidentCallback = ({ action, fullName, room, id }) => {
    if (action === "add") {
      data.residents.push({
        id,
        room,
        fullName,
      });
      setData(data);
      setResidents([...data.residents]);
    }
  };

  const addNewMemberCallback = ({ action, value }) => {
    if (action === "add") {
      data.teamMembers.push({
        id: data.teamMembers.length,
        name: value,
        selected: true,
        custom: true,
      });
      setData(data);
      setTeamMembers([...data.teamMembers]);
    }
  };

  const addNewResident = () => {
    openModal(
      <ResidentModal
        title="Enter Value"
        description="Select Resident"
        placeholder="Select a Resident..."
        errorMsg="* Please select a resident"
      />,
      addNewResidentCallback
    );
  };

  const addNewMember = () => {
    openModal(
      <InputModal
        title="Enter Value"
        description="Custom Team Member"
        placeholder="Custom team member..."
        errorMsg="* Please enter a value for custom team member"
      />,
      addNewMemberCallback
    );
  };

  return (
    <>
      <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          {!isLoading && (
            <MDBox
              color="white"
              bgColor="dark"
              variant="gradient"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="1.5rem"
              pl={2}
              pr={2}
              mt={-3}
            >
              <MDTypography variant="body2" color="white" fontSize="xs">
                Team members
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="body2" fontSize="xs">
            Select all the Team Members that are involved. You can also add custom entries.
          </MDTypography>
          <MDBox
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            mt={2}
            sx={{ borderBottom: `1px solid ${colors.grey[400]}` }}
            mb={1}
          >
            {teamMembers.map((member, index) => (
              <MDBox style={{ width: 260 }} display="flex" key={`teamMember-${index}`}>
                {member.custom && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={member.id}
                          checked
                          disabled={member.custom}
                          checkedIcon={<span className={classes.checkedIcon} />}
                        />
                      }
                      label={member.name}
                    />
                    <MDButton
                      variant="text"
                      color="secondary"
                      iconOnly
                      circular
                      onClick={() => deleteMember(member.name, index)}
                      sx={{ marginLeft: -1 }}
                      className={classes.button_delete}
                    >
                      <Icon>delete</Icon>
                    </MDButton>
                  </>
                )}
                {!member.custom && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={member.id}
                        checked={member.selected}
                        onChange={(event) => memberSelect(event, index)}
                      />
                    }
                    label={member.name}
                  />
                )}
              </MDBox>
            ))}
          </MDBox>
          <MDButton variant="gradient" color="info" style={{ marginTop: 5 }} onClick={addNewMember}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add custom
          </MDButton>
        </MDBox>
      </Card>
      <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="left">
          {!isLoading && (
            <MDBox
              color="white"
              bgColor="dark"
              variant="gradient"
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="1.5rem"
              pl={2}
              pr={2}
              mt={-3}
            >
              <MDTypography variant="body2" color="white" fontSize="xs">
                Residents (Optional)
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="body2" fontSize="xs">
            Add any residents who are involved (if any).
          </MDTypography>
          <MDBox
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            mt={2}
            sx={{ borderBottom: `1px solid ${colors.grey[400]}` }}
            mb={1}
          >
            {residents.map((resident, index) => (
              <MDBox style={{ width: 260 }} display="flex" key={`resident-${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={resident.id}
                      checked
                      disabled
                      checkedIcon={<span className={classes.checkedIcon} />}
                    />
                  }
                  label={resident.fullName}
                />
                <MDButton
                  variant="text"
                  color="secondary"
                  iconOnly
                  circular
                  sx={{ marginLeft: -1 }}
                  className={classes.button_delete}
                  onClick={() => deleteResident(resident.fullName, index)}
                >
                  <Icon>delete</Icon>
                </MDButton>
              </MDBox>
            ))}
          </MDBox>
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginTop: 5 }}
            onClick={addNewResident}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add residents
          </MDButton>
        </MDBox>
      </Card>
    </>
  );
};

export default PeopleInvolved;
