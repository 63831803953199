/* eslint-disable no-param-reassign */
import { DesktopDatePicker } from "@mui/x-date-pickers";
import colors from "assets/theme/base/colors";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Card, TextField, FormHelperText } from "@mui/material";
import MDEditor from "components/MDEditor";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

const DetailsBase = ({ isMobile, isLarge, data, setData, errors, setErrors }) => {
  const [title, setTitle] = useState(data.title);
  const [problemStatement, setProblemStatement] = useState(data.problemStatement);
  const [rootCause, setRootCause] = useState(data.rootCause);
  const [startDate, setStartDate] = useState(dayjs.unix(data.startDate).format("MM/DD/YYYY"));
  const [completionDate, setCompletionDate] = useState(
    dayjs.unix(data.completionDate).format("MM/DD/YYYY")
  );

  const setTitleFn = (val) => {
    data.title = val;
    setData(data);
    if (errors.title) {
      setErrors({ ...errors, title: false });
    }
    setTitle(val);
  };

  const setProblemStatementFn = (val) => {
    data.problemStatement = val;
    setData(data);
    if (errors.problemStatement) {
      setErrors({ ...errors, problemStatement: false });
    }
    setProblemStatement(val);
  };

  const setRootCauseFn = (val) => {
    data.rootCause = val;
    setData(data);
    if (errors.rootCause) {
      setErrors({ ...errors, rootCause: false });
    }
    setRootCause(val);
  };

  const setStartDateFn = (value) => {
    const val = dayjs(value).unix();
    data.startDate = val;
    setData(data);
    setStartDate(value);
  };

  const setCompletionDateFn = (value) => {
    const val = dayjs(value).unix();
    data.completionDate = val;
    setData(data);
    setCompletionDate(value);
  };

  useEffect(() => {
    if (errors.focus === "title") {
      document.getElementById("title").focus();
    } else if (errors.focus === "rootCause") {
      document.getElementById("rootCause").getElementsByClassName("ql-editor ")[0].focus();
    } else if (errors.focus === "problemStatement") {
      document.getElementById("problemStatement").getElementsByClassName("ql-editor ")[0].focus();
    }
  }, [errors]);

  return (
    <Card
      style={{
        backgroundColor: colors.grey[300],
        width: "100%",
        marginTop: 30,
      }}
    >
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="dark"
          variant="gradient"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="1.5rem"
          pl={2}
          pr={2}
          mt={-3}
        >
          <MDTypography variant="body2" color="white" fontSize="xs">
            Details
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" p={3}>
        <Grid container mb={2} spacing={3}>
          <Grid item xs={12} lg={6}>
            <MDTypography variant="body2" fontSize="xs">
              Title
            </MDTypography>
            <MDInput
              placeholder={isMobile ? "Title.." : "Enter a Title.."}
              style={{ width: "100%" }}
              value={title}
              onChange={(event) => setTitleFn(event.target.value)}
              error={errors.title}
              id="title"
            />
            <FormHelperText error>{errors.title ? "* Please enter a Title" : ""}</FormHelperText>
          </Grid>
          <Grid item xs={12} lg={6} pr={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={5}>
                <MDTypography variant="body2" fontSize="xs">
                  Start Date
                </MDTypography>
                <DesktopDatePicker
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDateFn(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        width: 160,
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={7} textAlign={isLarge ? "right" : "left"}>
                <MDTypography variant="body2" fontSize="xs">
                  Estimated Completion Date
                </MDTypography>
                <DesktopDatePicker
                  value={completionDate}
                  onChange={(newValue) => {
                    setCompletionDateFn(newValue);
                  }}
                  minDate={startDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        width: 160,
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MDTypography variant="body2" fontSize="xs">
              Problem Statement
            </MDTypography>
            <MDEditor
              placeholder={isMobile ? "Problem Statement.." : "Enter the problem statement.."}
              hideToolbar={isMobile}
              minHeight={100}
              value={problemStatement}
              onChange={setProblemStatementFn}
              error={errors.problemStatement}
              id="problemStatement"
            />
            <FormHelperText error>
              {errors.problemStatement ? "* Please enter a Problem Statement" : ""}
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDTypography variant="body2" fontSize="xs">
              Root Cause Analysis
            </MDTypography>
            <MDEditor
              placeholder={isMobile ? "Root Cause Analysis.." : "Enter the root cause analysis.."}
              hideToolbar={isMobile}
              minHeight={100}
              value={rootCause}
              onChange={setRootCauseFn}
              error={errors.rootCause}
              id="rootCause"
            />
            <FormHelperText error>
              {errors.rootCause ? "* Please enter a Root Cause" : ""}
            </FormHelperText>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default DetailsBase;
