import Grid from "@mui/material/Grid";

import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import useBackendError from "hooks/useBackendError";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import MDButton from "components/MDButton";

import { useNavigate } from "react-router-dom";
import MDSDetails from "./MDSDetails";

const MDS = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const loadMDS = async () => {
    try {
      const response = await axios.get(`mds/details`, {
        headers: { "Content-Type": "application/json" },
      });
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    loadMDS();
  }, []);

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12}>
        <Card id="residents-overview">
          <MDBox pt={2} px={2} display="flex" justifyContent="left" alignItems="center">
            <MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={1}
              px={2}
              mt={-4}
              mb={3}
            >
              <MDTypography variant="h6" fontWeight="medium" color="white">
                MDS
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mx={3}>{!isLoading && <MDSDetails data={data} />}</MDBox>
          <MDBox display="flex" justifyContent="center" alignItems="center" mt={-4} mb={3}>
            <MDButton
              onClick={handleBack}
              variant="gradient"
              color="info"
              size="medium"
              style={{ margin: 5, width: 100 }}
            >
              BACK
            </MDButton>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};
export default MDS;
