/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import "@szhsin/react-menu/dist/index.css";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useModal from "hooks/useModal";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import { timeout } from "utils/utils";
import MDButton from "components/MDButton";

import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import colors from "assets/theme/base/colors";
import ConfirmModal from "components/modal/ConfirmModal";
import EmailSatisfactionSurveyModal from "components/modal/EmailSatisfactionSurveyModal";
import StartSatisfactionSurveyModal from "components/modal/StartSatisfactionSurveyModal";
import { useNavigate } from "react-router-dom";
import Skeleton from "components/skeletons/Skeleton";

const ResidentActionCell = ({ id, name, room, emailId, isLoading, refreshCallback }) => {
  const { openModal } = useModal();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const deleteCallback = async () => {
    refreshCallback();
    console.log("deleteing...");
    await timeout(3000);
    /* await axios.post(`/qapi/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    return "success";
  };

  const deleteResident = () => {
    openModal(
      <ConfirmDeleteModal
        title="Delete Resident?"
        htmlText="Do you really want to delete the seleted resident?<br/>This action <b>cannot</b> be reverted"
        successText="The selected resident has been successfully deleted."
        callback={deleteCallback}
      />,
      () => {}
    );
  };

  const startNew = (returnVal) => {
    if (returnVal.action === "start") {
      navigate(`/resident-satisfaction/survey`, {
        state: {
          resident: returnVal.resident,
          template: returnVal.template,
          participant: returnVal.participant,
          type: returnVal.type,
          action: "edit",
        },
      });
    }
  };

  const emailNew = (returnVal) => {
    if (returnVal.action === "send") {
      let html = `<p>An email with the survey link and instructions has been sent to <span style='color: ${colors.primary.main}'>${returnVal.email}</span>.`;
      if (returnVal.notify) {
        html += " <br>You will be notified once they complete the survey.</p>";
      }
      setTimeout(() => {
        openModal(
          <ConfirmModal buttonLabels={["okay"]} icon="success" htmlText={html} />,
          () => {}
        );
      }, 100);
    }
  };

  const emailResidentSatisfactionSurvey = () => {
    openModal(
      <EmailSatisfactionSurveyModal residentId={id} residentName={name} emailId={emailId} />,
      emailNew
    );
  };

  const startResidentSatisfactionSurvey = () => {
    openModal(<StartSatisfactionSurveyModal residentId={id} residentName={name} />, startNew);
  };

  const startQAPI = () => {
    navigate(`/qapi/details?`, {
      state: { residentId: id, residentRoom: room, residentName: name },
    });
  };

  if (isLoading) {
    return <Skeleton width={80} height={40} />;
  }

  return (
    <MDBox display="flex" alignItems="center">
      <Menu
        menuButton={
          <MDButton variant="gradient" color="info" style={{ margin: 5, width: 80 }}>
            Action
          </MDButton>
        }
        transition
      >
        <MenuItem>View</MenuItem>
        <SubMenu label="Update">
          <MenuItem>Manually</MenuItem>
          <MenuItem>MDS Upload</MenuItem>
        </SubMenu>
        <SubMenu label="Resident Satisfaction Survey">
          <MenuItem onClick={startResidentSatisfactionSurvey}>Start Survey</MenuItem>
          <MenuItem onClick={emailResidentSatisfactionSurvey}>Email Survey</MenuItem>
        </SubMenu>
        <MenuItem onClick={startQAPI}>Start QAPI</MenuItem>
        <MenuItem onClick={deleteResident}>
          <span style={{ color: colors.error.main }}>Delete Resident</span>
        </MenuItem>
      </Menu>
    </MDBox>
  );
};

// Typechecking props for the StatusCell
ResidentActionCell.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ResidentActionCell;
