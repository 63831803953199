/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { makeStyles } from "@material-ui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Skeleton,
  Tooltip,
  colors,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBadgeDot from "components/MDBadgeDot";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDHr from "components/MDHr";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import TimelineItem from "components/Timeline/TimelineItem";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import UserPasswordReset from "components/modal/UserPasswordReset";
import dayjs from "dayjs";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import useModal from "hooks/useModal";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useNavigate, useSearchParams } from "react-router-dom";
import { timeout } from "utils/utils";

const relativeTime = require("dayjs/plugin/relativeTime");

const useStyles = makeStyles(() => ({
  accordionSummary: {
    minHeight: 40,
    maxHeight: 40,
    "&.Mui-expanded": {
      minHeight: 40,
      maxHeight: 40,
      backgroundColor: colors.grey[100],
    },
  },
  accordionDetail: {
    backgroundColor: colors.grey[200],
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  accordion: {
    boxShadow: "none",
    "&:before": {
      display: "none", // Remove the pseudo-element
    },
    "& .MuiPaper-root": {
      borderRadius: 10,
    },
  },
  checkedIcon: {
    backgroundColor: colors.grey[500],
    borderRadius: 4,
    marginLeft: 1,
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
}));

function UserDetail() {
  const [isLoading, setLoading] = useState(true);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [activityLogLoading, setActivityLogLoading] = useState(true);
  const [activityFetchInProgress, setActivityFetchInProgress] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const [user, setUser] = useState({});
  const [tempUser, setTempUser] = useState(user);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { openModal } = useModal();
  const navigate = useNavigate();
  const [isEditMode, setEditMode] = useState(false);
  const [facilityAccess, setFacilityAccess] = useState();
  const [isAdmin, setAdmin] = useState();
  const [isSurveyExpanded, setSurveyExpanded] = useState(false);
  const [isQapiExpanded, setQapiExpanded] = useState(false);
  const classes = useStyles();
  const [activityLog, setActivityLog] = useState([]);
  const activityLogPaginationKey = useRef();

  const handleExpandCollapseSurvey = () => {
    setSurveyExpanded(!isSurveyExpanded);
  };

  const handleExpandCollapseQapi = () => {
    setQapiExpanded(!isQapiExpanded);
  };

  const setFacilityAccessFn = (event, index) => {
    facilityAccess[index].selected = event.target.checked;
    setFacilityAccess([...facilityAccess]);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/user?id=${id}`, {
        headers: { "Content-Type": "application/json" },
      });
      setUser(response.data);
      setTempUser(response.data);
      setAdmin(response.data.admin);
      setFacilityAccess(response.data.facilities);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const loadActivityLog = async () => {
    if (activityFetchInProgress) {
      return;
    }
    setActivityFetchInProgress(true);
    if (activityLogPaginationKey.current == null) {
      setActivityLogLoading(true);
    }
    try {
      const response = await axios.get(
        `/user/activity?id=${id}${
          activityLogPaginationKey.current == null
            ? ""
            : `&paginationKey=${activityLogPaginationKey.current}`
        }`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setActivityLog(activityLog.concat(response.data.activities));
      activityLogPaginationKey.current = response.data.paginationKey;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setActivityLogLoading(false);
      setActivityFetchInProgress(false);
    }
  };

  useEffect(() => {
    loadData();
    loadActivityLog();
  }, []);

  const isOnline = user.lastOnline >= Date.now() - 1000 * 60 * 2;
  dayjs.extend(relativeTime);

  const ago = dayjs(dayjs.unix(user.lastOnline)).fromNow();

  const deleteCallback = async () => {
    console.log("deleteing...");
    await timeout(3000);
    /* await axios.post(`/qapi/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    return "success";
  };

  const modalReturnedValue = (result) => {
    if (result.action === "cancel") {
      return;
    }
    navigate(`/users`);
  };

  const deleteUser = () => {
    openModal(
      <ConfirmDeleteModal
        title="Delete User?"
        text="Do you really want to delete this user?"
        successText="User has been successfully deleted."
        callback={deleteCallback}
      />,
      modalReturnedValue
    );
  };

  const passwordReset = async () => {
    setResetPasswordLoading(true);
    try {
      const response = await axios.get(`/facility?includeDefaultPassword=true`, {
        headers: { "Content-Type": "application/json" },
      });
      openModal(
        <UserPasswordReset user={user} defaultPassword={response.data.defaultPassword} />,
        () => {}
      );
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setResetPasswordLoading(false);
    }
  };

  const changeEmail = (val) => {
    user.email = val;
    setTempUser({ ...user });
  };

  const changeFirstName = (val) => {
    user.firstName = val;
    setTempUser({ ...user });
  };

  const changeMiddleName = (val) => {
    user.middleName = val;
    setTempUser({ ...user });
  };

  const changeLastName = (val) => {
    user.lastName = val;
    setTempUser({ ...user });
  };

  const save = async () => {
    setSaving(true);
    tempUser.facilities = facilityAccess;
    tempUser.admin = isAdmin;
    try {
      const response = await axios.post(`/user?id=${id}`, tempUser, {
        headers: { "Content-Type": "application/json" },
      });
      setUser(response.data);
      setTempUser(response.data);
      setAdmin(response.data.admin);
      setFacilityAccess(response.data.facilities);
    } catch (err) {
      setError("Failed to save to the backend. Please try again");
    } finally {
      setSaving(false);
      setEditMode(false);
    }
  };

  const facCount = isEditMode
    ? facilityAccess?.length
    : user?.facilities?.filter((fac) => fac.hasAccess)?.length;

  const facScrollHeight = facCount === 1 ? 40 : facCount === 2 ? 75 : 95;

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const calculatedScrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (calculatedScrollPercentage >= 80) {
      loadActivityLog();
    }
  };

  return (
    <MDBox display="flex" alignItems="center">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card id="user-main" style={{ marginBottom: 24 }}>
            <MDBox pt={2} px={2} display="flex" justifyContent="left" alignItems="center">
              {isLoading && (
                <MDBox
                  borderRadius="xl"
                  shadow="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={-4}
                  mb={3}
                  bgColor={colors.grey[300]}
                  width={140}
                  height={140}
                />
              )}
              {!isLoading && (
                <MDBox
                  borderRadius="xl"
                  shadow="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={-4}
                  mb={3}
                >
                  <img
                    src={user.url}
                    alt="dp"
                    style={{ width: 140, height: 140, borderRadius: 12 }}
                  />
                </MDBox>
              )}
              {!isEditMode && (
                <MDBox ml={3} mt={-4} pt={2}>
                  {!isLoading && (
                    <>
                      <MDBox display="flex" alignItems="center">
                        <MDTypography variant="body2" fontWeight="bold" color="dark">
                          {user.fullName}
                        </MDTypography>
                        <MDBox
                          px={1}
                          ml={1}
                          mt={0.2}
                          borderRadius="md"
                          style={{ backgroundColor: colors.grey[100] }}
                        >
                          <MDTypography
                            variant="body2"
                            fontWeight="regular"
                            color="text"
                            style={{ fontSize: 13 }}
                          >
                            {user.username}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      {isOnline && (
                        <MDBox>
                          <MDBadgeDot
                            badgeContent="online"
                            color="success"
                            font={{ color: "text" }}
                          />
                        </MDBox>
                      )}
                      {!isOnline && (
                        <MDBox display="flex">
                          <Icon
                            sx={{
                              color: colors.grey[500],
                              marginTop: 0.5,
                              fontSize: "12px !important",
                            }}
                          >
                            access_time
                          </Icon>
                          <MDTypography color="text" sx={{ fontSize: 12, marginLeft: 0.3 }}>
                            {ago}
                          </MDTypography>
                        </MDBox>
                      )}
                      {user.admin && (
                        <MDBadge
                          variant="contained"
                          badgeContent="Admin"
                          container
                          color="info"
                          sx={{ marginRight: 1, marginTop: 0.2 }}
                        />
                      )}

                      <MDTypography
                        variant="body2"
                        fontWeight="regular"
                        color="text"
                        style={{ fontSize: 13, marginTop: 4 }}
                      >
                        Joined on {dayjs.unix(user.dateOfJoin).format("DD MMM, YYYY")}
                      </MDTypography>
                    </>
                  )}

                  {isLoading && (
                    <>
                      <MDBox display="flex" alignItems="center">
                        <MDTypography variant="body2" fontWeight="bold" color="dark">
                          <Skeleton height={40} width={160} />
                        </MDTypography>
                        <MDBox
                          px={1}
                          ml={1}
                          mt={0.2}
                          borderRadius="md"
                          style={{ backgroundColor: colors.grey[100] }}
                        >
                          <Skeleton height={24} width={100} />
                        </MDBox>
                      </MDBox>
                      <Skeleton height={26} width={100} />
                      <Skeleton height={24} width={60} />
                      <Skeleton height={20} width={160} />
                    </>
                  )}
                </MDBox>
              )}
              {isEditMode && (
                <MDBox>
                  <MDBox ml={3} mt={-4} pt={2} display="flex">
                    <MDBox display="flex" flexDirection="column">
                      <MDTypography
                        variant="body2"
                        fontWeight="regular"
                        color="text"
                        style={{ fontSize: 13 }}
                        textAlign="left"
                      >
                        First Name:
                      </MDTypography>
                      <MDInput
                        placeholder="First Name.."
                        style={{ width: "100%", marginTop: 4 }}
                        value={tempUser.firstName}
                        onChange={(event) => changeFirstName(event.target.value)}
                        // error={errorLongHospitalization != null}
                        id="title"
                      />
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" ml={0.5}>
                      <MDTypography
                        variant="body2"
                        fontWeight="regular"
                        color="text"
                        style={{ fontSize: 13 }}
                        textAlign="left"
                      >
                        Middle Name:
                      </MDTypography>
                      <MDInput
                        placeholder="Middle Name.."
                        style={{ width: "100%", marginTop: 4 }}
                        value={tempUser.middleName}
                        onChange={(event) => changeMiddleName(event.target.value)}
                        // error={errorLongHospitalization != null}
                        id="title"
                      />
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" ml={0.5}>
                      <MDTypography
                        variant="body2"
                        fontWeight="regular"
                        color="text"
                        style={{ fontSize: 13 }}
                        textAlign="left"
                      >
                        Last Name:
                      </MDTypography>
                      <MDInput
                        placeholder="Last Name.."
                        style={{ width: "100%", marginTop: 4 }}
                        value={tempUser.lastName}
                        onChange={(event) => changeLastName(event.target.value)}
                        // error={errorLongHospitalization != null}
                        id="title"
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox ml={3} pt={1.5}>
                    <MDHr color={colors.grey[300]} style={{ width: "100%" }} />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="isAdmin"
                          checked={isAdmin}
                          onChange={(event) => setAdmin(event.target.checked)}
                        />
                      }
                      label={
                        <MDTypography display="inline" variant="body2">
                          Has Administrator Privilages
                        </MDTypography>
                      }
                    />
                  </MDBox>
                </MDBox>
              )}
            </MDBox>

            <MDBox px={2}>
              <MDHr color={colors.grey[300]} style={{ width: "100%" }} />
              <MDBox display="flex" mt={2} alignItems="center">
                {isLoading && <Skeleton height={32} width={180} margin="0px" />}
                {!isLoading && (
                  <>
                    <MDTypography variant="body2" fontWeight="regular" color="text">
                      Email:
                    </MDTypography>
                    {!isEditMode && (
                      <MDTypography
                        variant="body2"
                        fontWeight="regular"
                        color="dark"
                        style={{ marginLeft: 4 }}
                      >
                        {user.email}
                      </MDTypography>
                    )}
                    {isEditMode && (
                      <MDInput
                        placeholder="Enter Email ID.."
                        style={{ width: 360, marginLeft: 6 }}
                        value={tempUser.email}
                        onChange={(event) => changeEmail(event.target.value)}
                        // error={errorLongHospitalization != null}
                        id="title"
                      />
                    )}
                  </>
                )}
              </MDBox>
              <MDBox mt={2}>
                {isLoading && <Skeleton height={90} width="100%" margin="0px" />}
                {!isLoading && (
                  <>
                    <MDTypography variant="body2" fontWeight="regular" color="text">
                      Facilities:
                    </MDTypography>
                    {!isLoading && (
                      <Scrollbars
                        style={{
                          height: facScrollHeight,
                          backgroundColor: colors.grey[100],
                          borderRadius: 8,
                          marginTop: 6,
                          marginBottom: 6,
                        }}
                        renderTrackHorizontal={({ style, ...props }) => <div />}
                        renderTrackVertical={({ style, ...props }) => (
                          <div className="scrollbar-regular-vertical-track" {...props} />
                        )}
                        renderThumbVertical={({ style, ...props }) => (
                          <div
                            style={{
                              ...style,
                              backgroundColor: "rgba(0, 0, 0, 0.2)",
                              borderRadius: 8,
                              width: 6,
                              cursor: "pointer",
                            }}
                            {...props}
                          />
                        )}
                      >
                        <MDBox py={1} height="100%">
                          {!isEditMode &&
                            user.facilities
                              .filter((facility) => facility.selected)
                              .map((facility, index) => (
                                <MDBox py={0.2} pl={2} pr={3} key={`facility-${facility.id}`}>
                                  <MDBox display="flex">
                                    <Icon fontSize="medium" color="success">
                                      check
                                    </Icon>

                                    <MDTypography
                                      variant="body2"
                                      fontWeight="regular"
                                      color="dark"
                                      style={{ fontSize: 14, marginLeft: 4 }}
                                    >
                                      {facility.name}
                                    </MDTypography>
                                  </MDBox>
                                  {index !==
                                    user.facilities.filter((fac) => fac.selected).length - 1 && (
                                    <MDHr
                                      color={colors.grey[300]}
                                      style={{ width: "100%", marginTop: 6 }}
                                    />
                                  )}
                                </MDBox>
                              ))}
                          {isEditMode &&
                            facilityAccess.map((facility, index) => (
                              <MDBox py={0.2} pl={2} pr={3} key={`facility-${facility.id}`}>
                                <MDBox display="flex" my={-0.8}>
                                  {facility.hasAccess && (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          value="notification"
                                          checked={facility.selected}
                                          onChange={(event) => setFacilityAccessFn(event, index)}
                                        />
                                      }
                                      label={
                                        <MDTypography display="inline" variant="body2">
                                          {facility.name}
                                        </MDTypography>
                                      }
                                    />
                                  )}
                                  {!facility.hasAccess && (
                                    <Tooltip
                                      title="You do not have access to this facility"
                                      enterDelay={1000}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="notification"
                                            checked={facility.selected}
                                            checkedIcon={<span className={classes.checkedIcon} />}
                                          />
                                        }
                                        label={
                                          <MDTypography
                                            display="inline"
                                            variant="body2"
                                            color="text"
                                          >
                                            {facility.name}
                                          </MDTypography>
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </MDBox>

                                {index !== facilityAccess.length - 1 && (
                                  <MDHr
                                    color={colors.grey[300]}
                                    style={{ width: "100%", marginTop: 6 }}
                                  />
                                )}
                              </MDBox>
                            ))}
                        </MDBox>
                      </Scrollbars>
                    )}
                  </>
                )}
              </MDBox>
              {!isEditMode && (
                <MDBox display="flex" my={2} justifyContent="center">
                  <MDButton
                    onClick={deleteUser}
                    variant="gradient"
                    color="light"
                    size="medium"
                    style={{ margin: 5, width: "25%", maxWidth: 120 }}
                  >
                    delete&nbsp;
                    <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                  </MDButton>
                  <MDButton
                    onClick={passwordReset}
                    variant="gradient"
                    color="info"
                    size="medium"
                    style={{ margin: 5, width: "60%", maxWidth: 180 }}
                    disabled={resetPasswordLoading}
                  >
                    reset password&nbsp;
                    <Icon sx={{ fontWeight: "bold" }}>key</Icon>
                  </MDButton>
                  <MDButton
                    onClick={() => setEditMode(true)}
                    variant="gradient"
                    color="primary"
                    size="medium"
                    style={{ margin: 5, width: "15%", maxWidth: 100 }}
                  >
                    edit&nbsp;
                    <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                  </MDButton>
                </MDBox>
              )}
              {isEditMode && (
                <MDBox display="flex" my={2} justifyContent="center">
                  <MDButton
                    onClick={() => setEditMode(false)}
                    variant="gradient"
                    color="light"
                    size="medium"
                    style={{ margin: 5, width: "50%", maxWidth: 160 }}
                  >
                    cancel
                  </MDButton>
                  <MDButton
                    onClick={save}
                    variant="gradient"
                    color="primary"
                    size="medium"
                    loading={isSaving}
                    loadingText="saving..."
                    style={{ margin: 5, width: "50%", maxWidth: 160 }}
                  >
                    save&nbsp;
                    <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          </Card>
          <Card id="surveys" style={{ marginBottom: 24 }}>
            <MDBox style={{ borderRadius: 10, overflow: "hidden" }}>
              <Accordion
                disableGutters
                className={classes.accordion}
                onChange={handleExpandCollapseSurvey}
                expanded={isSurveyExpanded}
              >
                <AccordionSummary>
                  <MDBox
                    mx={1}
                    display="flex"
                    justifyContent="space-between"
                    style={{ width: "100%" }}
                  >
                    <MDTypography variant="h6" fontWeight="medium" mt={0.2}>
                      LTCSP Mock Surveys
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{ marginLeft: 10 }}
                    >
                      {isSurveyExpanded ? "COLLAPSE" : "EXPAND"}
                    </MDButton>
                  </MDBox>
                </AccordionSummary>
                <AccordionDetails>TEST</AccordionDetails>
              </Accordion>
            </MDBox>
          </Card>
          <Card id="QAPIS" style={{ marginBottom: 24 }}>
            <MDBox style={{ borderRadius: 10, overflow: "hidden" }}>
              <Accordion
                disableGutters
                className={classes.accordion}
                onChange={handleExpandCollapseQapi}
                expanded={isQapiExpanded}
              >
                <AccordionSummary>
                  <MDBox
                    mx={1}
                    display="flex"
                    justifyContent="space-between"
                    style={{ width: "100%" }}
                  >
                    <MDTypography variant="h6" fontWeight="medium" mt={0.2}>
                      QAPIs
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{ marginLeft: 10 }}
                    >
                      {isQapiExpanded ? "COLLAPSE" : "EXPAND"}
                    </MDButton>
                  </MDBox>
                </AccordionSummary>
                <AccordionDetails>TEST</AccordionDetails>
              </Accordion>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card id="user-main" style={{ height: "100%" }}>
            <MDBox px={2} py={3} style={{ height: "100%" }}>
              <MDTypography variant="h6" fontWeight="medium" mb={2}>
                Activity Log
              </MDTypography>
              <Scrollbars
                onScroll={handleScroll}
                style={{
                  height: "calc(100% - 35px)",
                  backgroundColor: colors.grey[100],
                  borderRadius: 8,
                }}
                renderTrackHorizontal={({ style, ...props }) => <div />}
                renderTrackVertical={({ style, ...props }) => (
                  <div className="scrollbar-regular-vertical-track" {...props} />
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      borderRadius: 8,
                      width: 6,
                      cursor: "pointer",
                    }}
                    {...props}
                  />
                )}
              >
                {!activityLogLoading && (
                  <MDBox p={2}>
                    {activityLog.map((log, index) => (
                      <TimelineItem
                        key={`activity-${index}`}
                        color={log.color}
                        icon={log.icon}
                        title={log.title}
                        dateTime={dayjs.unix(log.dateTime).format("MMM DD, YYYY - hh:mm A")}
                        description={log.description}
                        lastItem={index === activityLog.length - 1}
                      />
                    ))}
                  </MDBox>
                )}
              </Scrollbars>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default UserDetail;
