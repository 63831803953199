/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import "./global-styles.css";

import { useState, useEffect, useRef, createRef, useCallback } from "react";

// react-router components
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "components/Sidenav";

// Material Dashboard 2 React routes
import routes from "routes";
import innerRoutes from "innerRoutes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";
import MDAlert from "components/MDAlert";
// Images
import MDTypography from "components/MDTypography";
import useBackendError from "hooks/useBackendError";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { ROLES, AUTO_LOGOUT_MS } from "utils/utils";
import useAuth from "hooks/useAuth";
import Logout from "pages/Logout";
import Scrollbars from "react-custom-scrollbars";
import BaseLayout from "components/BaseLayout";
import useSize from "hooks/useSize";
import useScroll from "hooks/useScroll";
import { useIdleTimer } from "react-idle-timer";
import Login from "./pages/Login";
import Missing from "./pages/Missing";
import PersistLogin from "./pages/PersistLogin";
import RequireAuth from "./pages/RequireAuth";
import Dashboard from "./pages/Dashboard";
import Unauthorized from "./pages/Unauthorized";
import Layout from "./pages/Layout";
import "components/Scrollbars/scrollbar.css";

export default function App() {
  const navigate = useNavigate();
  const { error, setError } = useBackendError();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { auth } = useAuth();
  const { scrollTo } = useScroll();
  const { setAuth } = useAuth();

  const getLoggedInRoutes = useCallback(
    (allRoutes) =>
      allRoutes.map((route) => {
        if (route.collapse) {
          return getLoggedInRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              exact
              element={<RequireAuth allowedRoles={route.allowedRoles} />}
              key={`${route.key}-parent`}
            >
              <Route
                path={route.route}
                element={<BaseLayout stickyNavbar>{route.component}</BaseLayout>}
                key={route.key}
              />
            </Route>
          );
        }
        return null;
      }),
    []
  );

  const getLoggedInInnerRoutes = useCallback(
    (allRoutes) =>
      allRoutes.map((route) => (
        <Route
          exact
          element={<RequireAuth allowedRoles={route.allowedRoles} />}
          key={`${route.key}-parent`}
        >
          <Route
            path={route.route}
            element={<BaseLayout stickyNavbar>{route.component}</BaseLayout>}
            key={route.key}
          />
        </Route>
      )),
    []
  );

  const { pathname } = useLocation();

  const onIdle = () => {
    if (
      routes.filter((route) => route.route === pathname).length !== 0 ||
      innerRoutes.filter((route) => route.route === pathname).length !== 0
    ) {
      setAuth({});
      navigate("/login");
      setError("Your session expired. Please login again");
    }
  };

  useIdleTimer({
    onIdle,
    timeout: AUTO_LOGOUT_MS,
  });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = useCallback(() => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  }, [miniSidenav, onMouseEnter, dispatch]);

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = useCallback(() => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  }, [miniSidenav, onMouseEnter, dispatch]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    // scrollRef.current.scrollToTop();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    //  scrollRef.current.scrollTop(scrollTo);
    document.documentElement.scrollTop = scrollTo;
    document.scrollingElement.scrollTop = scrollTo;
  }, [scrollTo]);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 5000);
  }, [error]);

  return (
    <>
      <CssBaseline />
      {layout === "dashboard" &&
        auth?.roles?.some((r) => [ROLES.User, ROLES.Admin, ROLES.Corporate].indexOf(r) >= 0) && (
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Stars For Care"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
      {error && (
        <MDAlert
          color="error"
          dismissible
          style={{ position: "fixed", zIndex: 2000, margin: 10, right: 0 }}
        >
          <MDTypography variant="body2" fontWeight="regular" color="white">
            {error}
          </MDTypography>
        </MDAlert>
      )}
      <Routes>
        <Route path="/" element={<Layout />} key="layout">
          {/* public routes */}
          <Route path="login" element={<Login />} key="login" />
          <Route path="unauthorized" element={<Unauthorized key="unauthorized" />} />
          <Route path="logout" element={<Logout />} key="logout" />

          {/* we want to protect these routes */}

          <Route element={<PersistLogin />} key="parent">
            <Route
              element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Admin, ROLES.Corporate]} />}
              key="parent-main"
            >
              <Route
                path="/"
                element={
                  <BaseLayout stickyNavbar>
                    <Dashboard />
                  </BaseLayout>
                }
                key="main"
              />
            </Route>
            {getLoggedInRoutes(routes)}
            {getLoggedInInnerRoutes(innerRoutes)}
          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} key="all" />
        </Route>
      </Routes>
    </>
  );
}
