import { colors } from "@mui/material";
import MDBox from "./MDBox";

/* eslint-disable react/destructuring-assignment */
const MDVr = (param) => (
  <MDBox
    width="2px"
    style={{
      ...param.style,
      borderImage: `linear-gradient(white, ${colors.grey[300]}, white) 1 / 2px`,
      borderRight: "none",
      height: "100%",
    }}
  />
);

export default MDVr;
