/* eslint-disable react/no-array-index-key */
import Grid from "@mui/material/Grid";

import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import useBackendError from "hooks/useBackendError";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";

import Scrollbars from "react-custom-scrollbars";
import colors from "assets/theme/base/colors";

import MDHr from "components/MDHr";
import Marquee from "components/Marquee";
import dayjs from "dayjs";
import Skeleton from "components/skeletons/Skeleton";
import useModal from "hooks/useModal";
import UploadMdsModal from "components/modal/UploadMdsModal";
import { useNavigate } from "react-router-dom";
import ResidentList from "./ResidentList";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const dummy = Array.from({ length: 20 }, () => ({}));

const ResidentsOverview = () => {
  const [isResidentListLoading, setResidentListLoading] = useState(true);
  const [isMdsListLoading, setMdsListLoading] = useState(true);
  const [isMdsFetchInProgress, setMdsFetchInProgress] = useState(false);
  const [residentList, setResidentList] = useState([]);
  const [mdsList, setMdsList] = useState([]);
  const mdsPaginationKey = useRef();
  const { openModal } = useModal();
  const navigate = useNavigate();

  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const loadUserList = async () => {
    try {
      const response = await axios.get(`/residents/list`, {
        headers: { "Content-Type": "application/json" },
      });
      setResidentList(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setResidentListLoading(false);
    }
  };

  const loadMDSList = async () => {
    if (isMdsFetchInProgress) {
      return;
    }
    setMdsFetchInProgress(true);
    if (mdsPaginationKey.current == null) {
      setMdsListLoading(true);
    }
    try {
      const response = await axios.get(
        `/mds/list${
          mdsPaginationKey.current == null ? "" : `?paginationKey=${mdsPaginationKey.current}`
        }`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setMdsList(mdsList.concat(response.data.mds));
      mdsPaginationKey.current = response.data.paginationKey;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setMdsFetchInProgress(false);
      setMdsListLoading(false);
    }
  };

  const loadData = async () => {
    loadUserList();
    loadMDSList();
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const calculatedScrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (calculatedScrollPercentage >= 80) {
      loadMDSList();
    }
  };
  const viewMDS = (id) => {
    navigate(`/residents/mds?id=${id}`);
  };

  const ref = useRef();
  const [dimensions, setDimensions] = useState(120);

  function handleWindowSizeChange() {
    setDimensions(ref.current.getBoundingClientRect());
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setDimensions(ref.current.getBoundingClientRect());
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const uploadMds = () => {
    openModal(<UploadMdsModal />, () => {});
  };

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item md={7.5} xs={12}>
        <Card id="residents-overview">
          <MDBox pt={2} px={2} display="flex" justifyContent="left" alignItems="center">
            <MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={1}
              px={2}
              mt={-4}
              mb={3}
            >
              <MDTypography variant="h6" fontWeight="medium" color="white">
                Residents
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox p={1} fontSize="1rem" style={{ textAlign: "center", width: "100%" }}>
            Upload MDS file(s) to add or update resident(s).
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pt={1}
            pb={4}
          >
            <MDButton variant="gradient" color="primary" style={{ margin: 5 }} onClick={uploadMds}>
              <Icon sx={{ fontWeight: "bold" }}>upload</Icon>
              &nbsp;Upload MDS File
            </MDButton>
          </MDBox>
        </Card>
        <Card id="users" style={{ marginTop: 20 }}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium">
              All Residents
            </MDTypography>
            <MDBox mt={2}>
              <ResidentList
                data={residentList}
                isLoading={isResidentListLoading}
                refreshCallback={loadUserList}
              />
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
      <Grid item md={4.5} xs={12}>
        <Card ref={ref} id="mds-log" style={{ height: "100%" }}>
          <MDBox p={1.5}>
            <MDTypography variant="h6" fontWeight="medium">
              MDS Uploads
            </MDTypography>
            <MDBox mt={2}>
              <Scrollbars
                onScroll={handleScroll}
                style={{
                  height: (dimensions?.height ?? 800) + 10,
                  backgroundColor: colors.grey[100],
                  borderRadius: 8,
                  marginTop: 6,
                  marginBottom: 6,
                }}
                renderTrackHorizontal={({ style, ...props }) => <div />}
                renderTrackVertical={({ style, ...props }) => (
                  <div className="scrollbar-regular-vertical-track" {...props} />
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      borderRadius: 8,
                      width: 6,
                      cursor: "pointer",
                    }}
                    {...props}
                  />
                )}
              >
                {!isMdsListLoading && (
                  <MDBox py={2} px={1}>
                    {mdsList?.map((mds, index) => (
                      <div key={index}>
                        <MDBox display="flex" justifyContent="space-between">
                          <MDBox>
                            <MDBox display="flex">
                              <Icon fontSize="medium" style={{ marginRight: 4 }}>
                                article_outlined
                              </Icon>
                              <Marquee
                                fontSize={14}
                                text={mds.filename}
                                width={(dimensions?.width ?? 40) - 170}
                                height={24}
                                fontWeight="regular"
                                underlineOnHover={false}
                                color="dark"
                              />
                            </MDBox>

                            <MDBox display="flex" justifyContent="space-between">
                              <MDTypography
                                variant="body2"
                                style={{
                                  fontSize: 12,
                                  color: colors.grey[500],
                                  marginLeft: 30,
                                  width: 85,
                                }}
                              >
                                {dayjs(dayjs.unix(mds.uploadedDate)).fromNow()}
                              </MDTypography>
                              <MDTypography
                                variant="body2"
                                style={{ fontSize: 12, color: colors.grey[500], marginLeft: 30 }}
                              >
                                {dayjs.unix(mds.uploadedDate).format("MMM DD, YYYY hh:mm A")}
                              </MDTypography>
                            </MDBox>
                          </MDBox>

                          <MDButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{ height: 26, marginRight: 10 }}
                            onClick={() => viewMDS(mds.id)}
                          >
                            VIEW
                          </MDButton>
                        </MDBox>
                        <MDHr
                          color={colors.grey[300]}
                          style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
                        />
                      </div>
                    ))}
                  </MDBox>
                )}
                {isMdsListLoading && (
                  <MDBox py={2} px={1}>
                    {dummy.map((dum, index) => (
                      <div key={index}>
                        <MDBox display="flex" justifyContent="space-between">
                          <MDBox>
                            <MDBox display="flex">
                              <Skeleton width={24} height={24} borderRadius={99} />
                              <Skeleton width={220} height={24} margin="0 0 0 10px" />
                            </MDBox>

                            <MDBox display="flex" justifyContent="space-between">
                              <Skeleton width={80} height={12} margin="0 0 0 36px" />
                              <Skeleton width={80} height={12} margin="0 0 0 10px" />
                            </MDBox>
                          </MDBox>

                          <Skeleton width={70} height={40} />
                        </MDBox>
                        <MDHr
                          color={colors.grey[300]}
                          style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
                        />
                      </div>
                    ))}
                  </MDBox>
                )}
              </Scrollbars>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};
export default ResidentsOverview;
