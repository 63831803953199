/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */

import { useEffect } from "react";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import colors from "assets/theme/base/colors";

import dayjs from "dayjs";
import MDAvatar from "components/MDAvatar";
import TimelineItem from "components/Timeline/TimelineItem";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const MDSDetails = ({ data }) => {
  console.log(data);
  useEffect(() => {
    // loadMDS();
  }, []);

  return (
    <MDBox m={1}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        pt={1}
        pb={1}
        pl={1}
        bgColor={colors.grey[300]}
        borderRadius={25}
        width="100%"
        style={{ minWidth: 600 }}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDAvatar
            bgColor="dark"
            src="https://i.postimg.cc/3NSQ7qr5/pexels-karolina-grabowska-8528405.jpg"
            size="sm"
          />
          <MDBox ml={1}>
            <MDTypography variant="body2" color="dark" sx={{ fontSize: 14 }}>
              Melvin Abraham
            </MDTypography>
            <MDTypography variant="body2" color="text" sx={{ fontSize: 12 }}>
              Room: 23
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography
            variant="body2"
            fontWeight="medium"
            color="dark"
            sx={{ fontSize: 14, marginRight: 1 }}
          >
            {data.filename}
          </MDTypography>
          <MDButton variant="gradient" color="info" size="small" style={{ marginRight: 10 }}>
            download
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <div
          style={{
            height: 24,
            borderLeft: `2px solid ${colors.grey[300]}`,
            marginLeft: 17,
            position: "relative",
            top: -16,
            marginBottom: -22,
          }}
        />
        {data.updates.map((log, index) => (
          <TimelineItem
            key={`activity-${index}`}
            color={log.color}
            icon={log.icon}
            title={log.title}
            lastItem={index === data.updates.length - 1}
          />
        ))}
      </MDBox>
    </MDBox>
  );
};
export default MDSDetails;
