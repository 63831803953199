/* eslint-disable no-nested-ternary */
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import "./confirmModal.css";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useEffect, useRef, useState } from "react";
import useBackendError from "hooks/useBackendError";

import MDBox from "components/MDBox";

import MDInput from "components/MDInput";
import MDHr from "components/MDHr";
import useAuth from "hooks/useAuth";
import colors from "assets/theme/base/colors";
import Scrollbars from "react-custom-scrollbars";
import MDLink from "components/MDLink";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { isValidUserId, isValidName, isValidEmailId } from "utils/utils";

const CreateNewUserModal = () => {
  const [isSuccess, setSuccess] = useState(false);
  const axios = useAxiosPrivate();
  const { closeModal } = useModal();
  const [trying, setTrying] = useState(false);
  const { auth } = useAuth();
  const userIdRef = useRef();
  const emailIdRef = useRef();
  const fNameRef = useRef();
  const mNameRef = useRef();
  const lNameRef = useRef();
  const [userId, setUserId] = useState("");
  const [userIdError, setUserIdError] = useState("");
  const { setError } = useBackendError();

  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState("");

  const [access, setAccess] = useState("user");

  const [fName, setFName] = useState("");
  const [fNameError, setFNameError] = useState("");

  const [mName, setMName] = useState("");
  const [mNameError, setMNameError] = useState("");

  const [lName, setLName] = useState("");
  const [lNameError, setLNameError] = useState("");

  const [facilityError, setFacilityError] = useState("");

  const [defaultPassword, setDefaultPassword] = useState("");
  const [facilities, setFacilities] = useState(
    auth?.profile?.facilities.map((fac) => ({ name: fac.name, id: fac.id, selected: false }))
  );
  const facScrollHeight = facilities.length === 1 ? 40 : facilities.length === 2 ? 75 : 95;

  const setFacilityAccessFn = (event, index) => {
    facilities[index].selected = event.target.checked;
    setFacilities([...facilities]);
    setFacilityError("");
  };

  const changeUserId = (event) => {
    setUserId(event.target.value);
    setUserIdError("");
  };

  const changeEmailId = (event) => {
    setEmailId(event.target.value);
    setEmailIdError("");
  };

  const changeFName = (event) => {
    setFName(event.target.value);
    setFNameError("");
  };

  const changeMName = (event) => {
    setMName(event.target.value);
    setMNameError("");
  };

  const changeLName = (event) => {
    setLName(event.target.value);
    setLNameError("");
  };

  const isUserIdUnavailable = async () => {
    try {
      const response = await axios.get(`/users/id/available?id=${userId}`, {
        headers: { "Content-Type": "application/json" },
      });
      return !response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    }
    return false;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`/facility?includeDefaultPassword=true`, {
          headers: { "Content-Type": "application/json" },
        });
        setDefaultPassword(response?.data?.defaultPassword);
      } catch (err) {
        setError("Failed to fetch from the backend. Please try again");
      }
    }
    fetchData();
  }, [axios, setError]);

  const tryCreate = async () => {
    setTrying(true);
    let error = false;
    if (lName === "") {
      setLNameError("* Last Name is required");
      error = true;
      lNameRef.current.focus();
    } else if (!isValidName(lName)) {
      setLNameError("* Invalid characters in name");
      error = true;
      lNameRef.current.focus();
    }
    if (mName !== "" && !isValidName(mName)) {
      setMNameError("* Invalid characters in name");
      error = true;
      mNameRef.current.focus();
    }
    if (fName === "") {
      setFNameError("* First Name is required");
      error = true;
      fNameRef.current.focus();
    } else if (!isValidName(fName)) {
      setFNameError("* Invalid characters in name");
      error = true;
      fNameRef.current.focus();
    }
    if (emailId === "") {
      setEmailIdError("* Email Id is required");
      error = true;
      emailIdRef.current.focus();
    } else if (!isValidEmailId(emailId)) {
      setEmailIdError("* Email Id is invalid");
      error = true;
      emailIdRef.current.focus();
    }
    if (userId === "") {
      setUserIdError("* User Id is required");
      error = true;
      userIdRef.current.focus();
    } else if (!isValidUserId(userId)) {
      setUserIdError("* User Id is invalid");
      error = true;
      userIdRef.current.focus();
    } else if (await isUserIdUnavailable(userId)) {
      setUserIdError("* User Id already taken");
      error = true;
      userIdRef.current.focus();
    }
    if (facilities.filter((fac) => fac.selected).length === 0) {
      setFacilityError("* You need to choose at least one facility");
      error = true;
    }
    setTrying(false);
    if (!error) {
      setTrying(true);
      try {
        const user = {
          username: userId,
          firstName: fName,
          middleName: mName,
          lastName: lName,
          email: emailId,
          facilities: facilities.map((fac) => ({ id: fac.id })),
          admin: access === "admin",
        };
        await axios.post(`/user`, user, {
          headers: { "Content-Type": "application/json" },
        });
        setSuccess(true);
      } catch (err) {
        setError("Failed to fetch from the backend. Please try again");
      }
    }
  };

  const selectAll = () => {
    const facs = facilities.map((facility) => ({
      name: facility.name,
      id: facility.id,
      selected: true,
    }));
    setFacilities(facs);
    setFacilityError("");
  };

  const deselectAll = () => {
    const facs = facilities.map((facility) => ({
      name: facility.name,
      id: facility.id,
      selected: false,
    }));
    setFacilities(facs);
    setFacilityError("");
  };

  const createAnother = () => {
    setEmailId("");
    setUserId("");
    setAccess("user");
    setFName("");
    setMName("");
    setLName("");
    deselectAll();
    setTrying(false);
    setSuccess(false);
  };

  if (isSuccess) {
    return (
      <div style={{ minWidth: 300 }}>
        <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
            Successfully created!
          </MDTypography>

          <div className="successContainer">
            <div className="successOne" />
            <span className="successTwo" />
            <span className="successThree" />
            <div className="successFour" />
            <div className="successFive" />
            <div className="successSix" />
          </div>

          <MDTypography display="inline" variant="body2" textAlign="center">
            New user successfully created.
          </MDTypography>

          <MDBox flexDirection="row" display="flex" m={1}>
            <MDBox m={1}>
              <MDButton onClick={createAnother} variant="gradient" color="info" size="medium">
                create another
              </MDButton>
            </MDBox>
            <MDBox m={1}>
              <MDButton
                onClick={() => closeModal({ action: "done" })}
                variant="gradient"
                color="primary"
                size="medium"
              >
                done
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: 600 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          Create User
        </MDTypography>
      </MDBox>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            User Id
          </MDTypography>

          <MDInput
            inputRef={userIdRef}
            placeholder="Enter a user Id"
            value={userId}
            onChange={(event) => changeUserId(event)}
            onPaste={(event) => changeUserId(event)}
            error={userIdError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>
            {userIdError !== "" ? (
              <>
                {userIdError}{" "}
                <Tooltip
                  title="Valid user Ids contain alphanummeric characters, dots, underscores and hyphens"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>

        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            Email Id
          </MDTypography>

          <MDInput
            inputRef={emailIdRef}
            placeholder="Enter email Id"
            value={emailId}
            onChange={(event) => changeEmailId(event)}
            onPaste={(event) => changeEmailId(event)}
            error={emailIdError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>{emailIdError !== "" ? emailIdError : null}</FormHelperText>
        </Grid>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            Access Type
          </MDTypography>

          <Select
            value={access}
            onChange={(event) => {
              setAccess(event.target.value);
            }}
            style={{ height: 40, width: "100%" }}
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            First Name
          </MDTypography>

          <MDInput
            inputRef={fNameRef}
            placeholder="Enter First Name"
            value={fName}
            onChange={(event) => changeFName(event)}
            onPaste={(event) => changeFName(event)}
            error={fNameError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>
            {fNameError !== "" ? (
              <>
                {fNameError}{" "}
                <Tooltip
                  title="Valid name contains alphanummeric characters, spaces, commas, dots, hyphens and apostrophes only"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            Middle Name
          </MDTypography>

          <MDInput
            inputRef={mNameRef}
            placeholder="Enter Middle Name"
            value={mName}
            onChange={(event) => changeMName(event)}
            onPaste={(event) => changeMName(event)}
            error={mNameError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>
            {mNameError !== "" ? (
              <>
                {mNameError}{" "}
                <Tooltip
                  title="Valid names contain alphanummeric characters, spaces, commas, dots, hyphens and apostrophes"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            Last Name
          </MDTypography>

          <MDInput
            inputRef={lNameRef}
            placeholder="Enter Last Name"
            value={lName}
            onChange={(event) => changeLName(event)}
            onPaste={(event) => changeLName(event)}
            error={lNameError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>
            {lNameError !== "" ? (
              <>
                {lNameError}{" "}
                <Tooltip
                  title="Valid name contains alphanummeric characters, spaces, commas, dots, hyphens and apostrophes only"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>
      </Grid>
      <MDBox mt={2}>
        <MDBox display="flex" width={220} justifyContent="space-between">
          <MDTypography variant="body2" textAlign="left">
            Facilities
          </MDTypography>
          {facilities?.length > 1 && (
            <>
              <MDLink text="Select All" onClick={selectAll} />
              <MDLink text="Deselect All" onClick={deselectAll} />
            </>
          )}
        </MDBox>

        <Scrollbars
          style={{
            height: facScrollHeight,
            backgroundColor: colors.grey[100],
            borderRadius: 8,
            marginTop: 6,
            marginBottom: 6,
            border: facilityError === "" ? null : `1px solid ${colors.error.main}`,
          }}
          renderTrackHorizontal={({ style, ...props }) => <div />}
          renderTrackVertical={({ style, ...props }) => (
            <div className="scrollbar-regular-vertical-track" {...props} />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 8,
                width: 6,
                cursor: "pointer",
              }}
              {...props}
            />
          )}
        >
          <MDBox py={1} height="100%" style={{ borderColor: "red" }}>
            {facilities.map((facility, index) => (
              <MDBox py={0.2} pl={2} pr={3} key={`facility-${facility.id}`}>
                <MDBox display="flex" my={-0.8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="notification"
                        checked={facility.selected}
                        onChange={(event) => setFacilityAccessFn(event, index)}
                      />
                    }
                    label={
                      <MDTypography display="inline" variant="body2">
                        {facility.name}
                      </MDTypography>
                    }
                  />
                </MDBox>

                {index !== facilities.length - 1 && (
                  <MDHr color={colors.grey[300]} style={{ width: "100%", marginTop: 6 }} />
                )}
              </MDBox>
            ))}
          </MDBox>
        </Scrollbars>
        <FormHelperText error>{facilityError !== "" ? facilityError : null}</FormHelperText>
      </MDBox>
      <MDTypography variant="body2" my={3}>
        An email will be send to the email Id provided above, with the username and the default
        password <span style={{ color: colors.primary.main }}>{defaultPassword}</span> (default
        password{" "}
        <Tooltip
          title="Default password can be changed in Settings"
          enterDelay={100}
          placement="top"
        >
          <Icon color="info" style={{ cursor: "pointer" }}>
            info
          </Icon>
        </Tooltip>
        )
      </MDTypography>

      <MDBox
        px={2}
        pb={2}
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
      >
        <MDBox mr={1}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
            m={1}
          >
            cancel
          </MDButton>
        </MDBox>
        <MDBox mr={1}>
          <MDButton
            onClick={tryCreate}
            variant="gradient"
            color="primary"
            size="medium"
            loading={trying}
            loadingText="processing..."
            style={{ width: 160 }}
          >
            create
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default CreateNewUserModal;
