import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Card, Icon } from "@mui/material";
import MDBadge from "components/MDBadge";
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Switch from "@mui/material/Switch";
import useBackendError from "hooks/useBackendError";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { makeStyles } from "@material-ui/styles";
import colors from "assets/theme/base/colors";

import { LocalizationProvider } from "@mui/x-date-pickers";
import MDButton from "components/MDButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ConfirmModal from "components/modal/ConfirmModal";
import useModal from "hooks/useModal";
import BarriersAndGoals from "./BarriersAndGoals";
import PeopleInvolved from "./PeopleInvolved";
import DetailsBase from "./DetailsBase";
import DetailsBaseSkeleton from "./skeleton/DetailsBaseSkeleton";
import BarriersAndGoalsSkeleton from "./skeleton/BarriersAndGoalsSkeleton";
import PipOccurance from "./PipOccurance";
import SupportingDocumentsEvaluations from "./SupportingDocumentsEvaluations";
import History from "./History";

const useStyles = makeStyles(() => ({
  switch_track: {
    backgroundColor: colors.warning.main,
    borderColor: `${colors.grey[500]} !important`,
  },
  switch_base: {
    borderColor: `${colors.grey[500]} !important`,
    color: colors.warning.main,
    "&.Mui-disabled": {
      color: "#e886a9",
    },
    "&.Mui-checked": {
      color: "#95cc97",
      borderColor: `${colors.grey[500]} !important`,
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#4CAF50 !important",
      borderColor: `${colors.grey[500]} !important`,
    },
  },
  switch_primary: {
    borderColor: `${colors.grey[500]} !important`,
    "&.Mui-checked": {
      color: "#4CAF50  !important",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#4CAF50  !important",
    },
  },
}));

const QapiDetails = () => {
  const { state } = useLocation();

  const [isLoading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [data, setData] = useState({
    teamMembers: [{}, {}],
  });
  const [submitting, setSubmitting] = useState(false);
  const [saving, setSaving] = useState(false);
  const axios = useAxiosPrivate();
  const [searchParams] = useSearchParams();
  const ftag = searchParams.get("ftag");
  const qapiId = searchParams.get("id");
  const { setError } = useBackendError();
  const navigate = useNavigate();
  const changed = useRef(false);
  const { openModal } = useModal();
  const [errors, setErrors] = useState({});
  const loadData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/qapi${qapiId != null ? `?id=${qapiId}` : ""}`, {
        headers: { "Content-Type": "application/json" },
      });
      if (qapiId == null && state?.residentId != null) {
        response.data.residents.push({
          id: state.residentId,
          room: state?.residentRoom,
          fullName: state?.residentName,
        });
      }
      if (ftag != null) {
        response.data.ftag = ftag;
      }
      setData(response.data);
      setStatus(!isLoading ? data.status === "COMPLETED" : "");
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    loadData();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const isLarge = width > 992;

  const classes = useStyles();

  const handleStatus = (event) => {
    if (event.target.checked) {
      setStatus("COMPLETED");
    } else {
      setStatus("ON_GOING");
    }
  };

  const setDataFn = (val) => {
    changed.current = true;
    setData(val);
  };
  const cancelConfirm = (prop) => {
    if (prop.action === "confirm") {
      navigate("/qapi");
    }
  };

  const cancel = () => {
    if (changed.current) {
      openModal(
        <ConfirmModal
          buttonLabels={["no", "yes"]}
          icon="warn"
          text="You have unsaved changes. Do you really want to go back?"
        />,
        cancelConfirm
      );
    } else {
      navigate("/compliance");
    }
  };

  const modalReturnedValue = () => {
    navigate("/qapi");
  };

  const submitToServer = async (message) => {
    const response = await axios.post(`/qapi`, data, {
      headers: { "Content-Type": "application/json" },
    });
    const finalMessage = (
      <>
        <span>{message}</span>
        <a href={`/qapi/details?id=${response.data}`}>Click Here</a>
        <span> to view it.</span>
      </>
    );
    openModal(
      <ConfirmModal buttonLabels={["okay"]} icon="success" text={finalMessage} />,
      modalReturnedValue
    );
  };

  const save = async () => {
    setSaving(true);
    try {
      await submitToServer("Qapi has been successfully saved! ");
    } catch (err) {
      setError("Failed to submit. Please try again");
    } finally {
      setSaving(false);
    }
  };

  const submit = async () => {
    setSubmitting(true);
    let isError = false;
    const newErrors = { ...errors, focus: null };
    if (data.pip === null || data.pip === "" || data.pip === "<p><br></p>") {
      newErrors.pip = true;
      newErrors.focus = "pip";
      isError = true;
    }
    if (data.rootCause === null || data.rootCause === "" || data.rootCause === "<p><br></p>") {
      newErrors.rootCause = true;
      newErrors.focus = "rootCause";
      isError = true;
    }
    if (
      data.problemStatement === null ||
      data.problemStatement === "" ||
      data.problemStatement === "<p><br></p>"
    ) {
      newErrors.problemStatement = true;
      newErrors.focus = "problemStatement";
      isError = true;
    }
    if (data.title === null || data.title === "") {
      newErrors.title = true;
      newErrors.focus = "title";
      isError = true;
    }
    if (isError) {
      setErrors(newErrors);
      setSubmitting(false);
    } else {
      try {
        await submitToServer("Qapi has been successfully submitted! ");
      } catch (err) {
        setError("Failed to submit. Please try again");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const getBadgeColor = (tag) => {
    if (tag === "ONGOING_FOR_A_WHILE") {
      return "error";
    }
    return "warning";
  };

  const getBadgeText = (tag) => tag.replace(/_/g, " ");

  return (
    <Grid container spacing={3} mb={3}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={12}>
          <Card
            id="compliance-overview"
            sx={{
              border: `2px solid ${
                status === "COMPLETED" ? colors.success.main : colors.warning.main
              }`,
            }}
          >
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDBox
                color="white"
                bgColor="info"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="2.5rem"
                pl={2}
                pr={2}
                mt={-4}
              >
                <MDTypography variant="h6" fontWeight="medium" color="white">
                  New QAPI
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="left"
              alignItems="center"
              flexWrap="wrap"
              p={isMobile ? 1 : 3}
            >
              <MDBox display="flex" justifyContent="space-between" width="100%">
                <MDBox>
                  <MDBadge
                    badgeContent={status === "COMPLETED" ? "Resolved" : "On-going"}
                    container
                    color={status === "COMPLETED" ? "success" : "warning"}
                    sx={{ marginRight: 1, marginTop: 0.2 }}
                  />
                  <Switch
                    classes={{
                      track: classes.switch_track,
                      switchBase: classes.switch_base,
                      colorPrimary: classes.switch_primary,
                    }}
                    color="secondary"
                    checked={status === "COMPLETED"}
                    onChange={handleStatus}
                  />
                </MDBox>
                <MDBox>
                  {status !== "COMPLETED" &&
                    data.tags != null &&
                    data.tags.map((tag) => (
                      <MDBadge
                        badgeContent={getBadgeText(tag)}
                        container
                        color={getBadgeColor(tag)}
                        sx={{ marginRight: 1, marginTop: 0.2 }}
                      />
                    ))}
                </MDBox>
              </MDBox>
              {!isLoading && (
                <DetailsBase
                  isLarge={isLarge}
                  data={data}
                  setData={setDataFn}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {isLoading && <DetailsBaseSkeleton />}
              {!isLoading && (
                <BarriersAndGoals isMobile={isMobile} data={data} setData={setDataFn} />
              )}
              {isLoading && <BarriersAndGoalsSkeleton />}
              {!isLoading && (
                <>
                  <PeopleInvolved data={data} setData={setDataFn} />
                  <PipOccurance
                    isMobile={isMobile}
                    data={data}
                    setData={setDataFn}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <SupportingDocumentsEvaluations data={data} setData={setDataFn} />
                  <History data={data} />
                </>
              )}
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              pb={3}
              pl={3}
              pr={3}
            >
              <MDButton variant="gradient" color="light" style={{ margin: 5 }} onClick={cancel}>
                cancel
              </MDButton>

              <MDButton
                variant="gradient"
                color="info"
                style={{ margin: 5, width: 180 }}
                onClick={save}
                loading={saving}
                loadingText="saving..."
              >
                <Icon sx={{ fontWeight: "regular" }}>save</Icon>
                &nbsp;save for later
              </MDButton>

              <MDButton
                variant="gradient"
                color="primary"
                style={{ margin: 5, width: 160 }}
                onClick={submit}
                loading={submitting}
                loadingText="submitting..."
              >
                submit&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>thumb_up</Icon>
              </MDButton>
            </MDBox>
          </Card>
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};
export default QapiDetails;
